import Vue from 'vue'
import VueRouter from 'vue-router'
import {has} from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: '/contactpreferences/:customerId/:uuid',
    name: 'contactpreferences',
    meta: { title: 'Manage your contact preferences'},
    component: () => import(/* webpackChunkName: "contactPreferences" */ '../views/ContactPreferences.vue')
  },
  {
    path: "*",
    meta: { title: 'Page not found'},
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(has(to, 'meta.title')) {
    document.title = to.meta.title;
  }
  next();
})

export default router
